import React, { Component }  from 'react';
import { GoogleMap, useLoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api'
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { observable, decorate } from "mobx";

import { mapStyles } from './mapStyles.js';


let google;
let clusterStyles = [{
    url: 'assets/img/gmap/m1.png',
    height: 52,
    width: 53,
  }, {
    url: 'assets/img/gmap/m2.png',
    height: 52,
    width: 53,
  }, {
    url: 'assets/img/gmap/m3.png',
    height: 52,
    width: 53,
  }];

class MapContainer extends Component{

  vehicules = [];
  markers = [];
  infoWindow = {
    visible: false,
    lat: -22.2198301,
    lng: 166.470882,
    anchor: null,
    content: ''
  }
  center = {
    lat: -22.2198301,
    lng: 166.470882,
  }

  constructor(props){
    super(props);
    this.vehicules = this.props.vehicules;
    this.state = {
      isOpen: false
    }
  }
  handleToggleOpen = () => {
    this.setState({
        isOpen: true
    });
  }

  handleToggleClose = () => {
    this.setState({
      isOpen: false
    });
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  setMarkersBounds = () => {
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.vehicules.length; i++ ) {  
      bounds.extend(new google.maps.LatLng(this.vehicules[i].latitude, this.vehicules[i].longitude));
    }
    return bounds;
  }

  onMarckerClick = (vehicule) => {
    this.infoWindow.visible = false;
    this.infoWindow = {
      visible: true,
      anchor: this.markers[vehicule.id],
      content: vehicule.name
    }
  }


  onWindowInfoClose = () => {
    this.infoWindow = {
      visible: false,
      anchor: null,
      content: ''
    }
  }

  renderMap = () => {
    google = window.google;
    const bounds = this.setMarkersBounds();

    return (
      <GoogleMap
        mapContainerStyle={this.props.styles.container}
        zoom={13}
        center={this.center}
        onLoad={(mapRef)=> {
          this.map = mapRef;
          mapRef.fitBounds(bounds);
        }}
        options={{
          styles: mapStyles.lightGrey,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
        }}>

        <MarkerClusterer
          averageCenter={true}
          styles={clusterStyles}
          minimumClusterSize={4}
        >
          { (clusterer) => this.vehicules.map((vehicule) => (
            <Marker
              key={vehicule.id}
              position={{
                lat: vehicule.latitude,
                lng: vehicule.longitude,
              }}
              clusterer={clusterer}
              clickable={true}
              onClick={() => this.onMarckerClick(vehicule)}
              onLoad={(marker) => this.markers[vehicule.id] = marker}
            />
          )) }
        </MarkerClusterer>
        { 
          this.infoWindow.visible && 
          <InfoWindow 
            anchor={this.infoWindow.anchor}
            onCloseClick={this.onWindowInfoClose}
          >
            <div>
              <span>{this.infoWindow.content}</span>
            </div>
          </InfoWindow>
        }
      </GoogleMap>
    )
  }

  render(){
    
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })
    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? this.renderMap() : this.loading();
  }
}


export default compose(
  observer,
)( 
  decorate( MapContainer, { 
    vehicules: observable,
    center: observable,
    infoWindow: observable
  })
);

