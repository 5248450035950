import { observable, action, decorate } from "mobx";

class BreadcrumbStore {
  
  routes = [];
  name = null;
  index = null;
  
  setRoutes(routes){
    this.routes = routes;
  }

  setRouteName(path, name){
    this.routes = this.routes.map(r => {
      r.name = r.path === path ? name : r.name;
      return r;
    });
  }

  getRouteName(match){
    const route = this.routes.find(r => r.path === match.path);
    return route ? route.name : null;
  }

  getRoute(url){
    const route = this.routes.find(r => r.path === url);
    return route || null;
  }

  getRouteClass(match){
    const route = this.routes.find(r => r.path === match.pathname);
    return route && route.class ? ['main', route.class] : 'main';
  }

  reset(){
    this.name = null;
    this.index = null;
  }

}

export default decorate( BreadcrumbStore, {
  setRouteName: action,
  name: observable,
  index: observable
});
