import React, { Component } from 'react';

import { am4core, globalSettings, am4Styles, scrollbarX, catAxisLabel } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";



class ChartLine extends Component {

  createData = () => {
    this.props.dataSeries.map(item => this.createSeries(...item));
  }

  createSeries = (key, name, bulletColor = null, color = null) => {
    let serie = this.chart.series.push(new am4charts.LineSeries());
    let bullet = serie.bullets.push(new am4charts.CircleBullet());
    serie.dataFields.valueY = key;
    serie.dataFields.categoryX = "category";
    serie.name = name;
    Object.assign(serie, am4Styles.serie);
    Object.assign(bullet, am4Styles.bullet);
    if(color) serie.stroke = am4core.color(color);
    if(bulletColor) bullet.fill = am4core.color(bulletColor);
    //bullet.fill = am4core.color(bulletColor ? bulletColor : "#c32021");
    return serie;
  }
  
  componentDidMount() {
    let seriesColor = this.props.dataSeries.map( (item) => { 
      return item[3];
    });
    let legend = this.props.dataSeries.length === 1 ? false : true;

    this.chart = am4core.create(this.props.id, am4charts.XYChart);
    this.chart.colors.step = 3;
    //this.chart.numberFormatter.numberFormat = "#.##";

    let watermark = new am4core.Label();
    
    globalSettings(this.chart, watermark, legend, seriesColor);    

    this.chart.paddingRight = this.props.paddingRight;
    this.chart.data = this.props.data;
    
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    //categoryAxis.layout = 'grid';
    categoryAxis.dataFields.category = "category";
    //categoryAxis.title.text = "Mois";
    //categoryAxis.renderer.grid.template.location = 1;
    //categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true;
    catAxisLabel(categoryAxis);

    
    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.layout = "absolute";
    valueAxis.title.text = "TOC";
    //valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    Object.assign(valueAxis.title, am4Styles.titleAxisY);

    this.createData();
    
    if(this.chart.data.length > 20 || this.props.zoom) scrollbarX(this.chart);
  


    
    //this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartLine;


