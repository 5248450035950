import React from 'react';
import { Input, Form, InputGroup, InputGroupAddon, InputGroupText  } from 'reactstrap';


export const FilterInput = (props) => (
  <Form inline style={props.style} >
    <InputGroup size="sm" className="mb-0 mr-2 mt-0">
      <InputGroupAddon addonType="prepend" >
        <InputGroupText className="bg-white"><i className="fa fa-search "/></InputGroupText>
      </InputGroupAddon>
      <Input bsSize="sm" name='filterValue' onChange={props.onChange} defaultValue={props.defaultValue} type="search" placeholder="Rechercher..." />
    </InputGroup>
  </Form>
)