import React, { Component } from 'react';

import { am4core, globalSettings } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";


class ChartColGroup extends Component {

  componentDidMount() {
    this.chart = am4core.create(this.props.id, am4charts.XYChart);
  
    let watermark = new am4core.Label();
    globalSettings(this.chart, watermark, true);  
    watermark.marginRight = 0;

    this.chart.data = this.props.data;

    // Create axes
    var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;

    // Modify chart's colors
    /*chart.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871"),
    ];*/

    this.chart.yAxes.push(new am4charts.ValueAxis());

    this.createData();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createData = () => {
    this.props.dataSeries.map(item => this.createSeries(...item))
  }

  createSeries = (key, name, bulletColor = null) => {
    var series = this.chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = key;
    series.dataFields.categoryX = "category";
    series.tooltip.pointerOrientation = "vertical";

    var columnTemplate = series.columns.template;
    columnTemplate.tooltipY = -6;
    columnTemplate.tooltipText = "{name}: {valueY}";
    columnTemplate.column.cornerRadius(5, 5, 0, 0);


    var labelBullet = new am4charts.LabelBullet();
    series.bullets.push(labelBullet);
    labelBullet.label.text = "{valueY}";
    labelBullet.label.fontSize = 12;    
    labelBullet.dy = 20;    
        
    return series;
  }
  /*createSeries = (key, name, bulletColor = null) => {
    let series = this.chart.series.push(new am4charts.LineSeries());
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    series.dataFields.valueY = key;
    series.dataFields.categoryX = "category";
    series.name = name;
    Object.assign(series, am4Styles.serie);
    Object.assign(bullet, am4Styles.bullet);
    bullet.fill =  am4core.color(bulletColor ? bulletColor : "#c32021");

    return series;
  }*/

  render() {
    return (
      <div id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartColGroup;


