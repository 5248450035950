import { computed, decorate, action ,observable} from "mobx";
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';

import { round, getTimeDiff } from 'utils/functions';

import { rentals } from 'constants/data/rentals.json';
import { vehicules } from 'constants/data/vehicules.json';
import { costData } from 'constants/data/costs.json';



class DataStore {
  
  rentals = rentals;
  vehicules = vehicules;
  costs = costData;

  changeVehiculeData(){
    this.vehicules[0].longitude = 166.43555;
    this.vehicules[0].latitude = -22.27555;
  }

  get rentalStatus(){
    return _groupBy(this.rentals, 'status');
  }

  get rentalMotifs(){
    let result = [];
    let sorted = _sortBy(_groupBy(this.rentalClosed, 'rental_label.name'), (item, key) => {
      item.id = item[0].rental_label.name;
      return -item.length;
    });
    for(var key in sorted) {
      result[sorted[key].id] = {};
      let id = sorted[key].id;
      delete sorted[key].id;    
      result[id] = sorted[key]; 
    }

    return result;
  }

  get rentalUsage(){
    return _groupBy(this.rentalClosed, 'vehicle.name');
  }

  get rentalClosed(){
    return this.rentals.filter(loc => loc.status === "closed");   
  }

  get rentalInProgress(){
    return this.rentals.filter(loc => loc.status === "in_progress");   
  }

  usageCount(key, list){
    let result = {};
    let distanceTotal = 0;
    if (list.hasOwnProperty(key)) {
      for (let index = 0; index < list[key].length; index++) {        
        distanceTotal = list[key][index].distance+distanceTotal;
      }
      result = {
        category: key,
        valueBar: list[key].length,
        valueLine: round(distanceTotal/1000, 1)
      };
    }

    return result;
  }

  get kmsPerVehicule(){
    let rentalUsage = this.rentalUsage;
    let rentalKMData = [];

    for (var key in rentalUsage) {
      let distanceTotal = 0;
      
      if (rentalUsage.hasOwnProperty(key)) {
        for (let index = 0; index < rentalUsage[key].length; index++) {        
          distanceTotal = rentalUsage[key][index].distance+distanceTotal;
        }
        rentalKMData.push({
          name: key,
          km: round(distanceTotal/1000, 1)
        });
      }
    }
    return _orderBy(rentalKMData, ['km'], ['asc']);
  }

  get vehiculeRentals(){
    let rentalUsage = this.rentalUsage;
    let rentalSize = [];
    for (var key in rentalUsage) {
      rentalSize.push({
        name: key,
        rentals: rentalUsage[key].length
      })
    }

    return _orderBy(rentalSize, ['rentals'], ['asc'] );
  }


  calculateCost(){
    let costs = this.costs;
    let costsPerTypes = [];
    for (const key in costs) {
      if (costs.hasOwnProperty(key)) {
        const element = costs[key];
        const elementType = element.type.label;
        const elementGeneral = element.general;
        const elementMaintenance = element.maintenance;
        const totalElementCost = this.calculateCostPerCat(elementGeneral)+this.calculateCostPerCat(elementMaintenance);

        if(!costsPerTypes[elementType]){
          costsPerTypes[elementType] = {
            value:totalElementCost,
            size: 1
          }
        }else{
          costsPerTypes[elementType] = {
            value: costsPerTypes[elementType].value + totalElementCost,
            size: costsPerTypes[elementType].size + 1
          }
        }
      }
    }
    return costsPerTypes;
  }

  calculateCostPerCat(values){
    let total = 0;
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        total = total+value.price;
      }
    }
    
    return total;

  }


  searchItem(value){
    return 'item';
  }

  findVehicule(id){   
    return this.vehicules.find(v => v.id === parseInt(id));
  }

}

export default decorate( DataStore, {
  vehicules: observable,
  changeVehiculeData: action,
  costs: observable
});
