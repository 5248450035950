import React, { Component } from 'react';

import { am4core, globalSettings } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";


class ChartColImg extends Component {

  componentDidMount() {
    this.chart = am4core.create(this.props.id, am4charts.XYChart);
  
    let watermark = new am4core.Label();
    globalSettings(this.chart, watermark);    
    watermark.marginBottom = 5;
    watermark.marginRight = 0;
    this.chart.paddingBottom = 0;


    this.chart.data = this.props.data;


    var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dy = 35;
    categoryAxis.renderer.tooltip.dy = 35;
    
    var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    //valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.5;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;

    
    
    var series = this.chart.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.tooltipText = "{valueY.value}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.dy = - 6;
    series.columnsContainer.zIndex = 100;

    var columnTemplate = series.columns.template;
    columnTemplate.width = am4core.percent(50);
    columnTemplate.maxWidth = 66;
    columnTemplate.column.cornerRadius(60, 60, 10, 10);
    columnTemplate.strokeOpacity = 0;



    series.heatRules.push({ 
      target: columnTemplate, 
      property: "fill", 
      dataField: "valueY", 
      //min: am4core.color("#e5dc36"), 
      //max: am4core.color("#5faa46") 
      min: am4core.color("#b70d07"), 
      max: am4core.color("#f95d57") 
    });
    series.mainContainer.mask = undefined;
    
    var cursor = new am4charts.XYCursor();
    this.chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = "none";
    
    var bullet = columnTemplate.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 30;
    bullet.valign = "bottom";
    bullet.align = "center";
    bullet.isMeasured = true;
    bullet.mouseEnabled = false;
    bullet.verticalCenter = "bottom";    

    

    bullet.states.create("hover");
    var outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add("radius", function (radius, target) {
        var circleBullet = target.parent;
        return circleBullet.circle.pixelRadius + 10;
    });
    

    var image = bullet.createChild(am4core.Image);
    image.width = 60;
    image.height = 60;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";
    
    image.adapter.add("href", function (href, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        return "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/" + dataItem.categoryX.toLowerCase() + ".jpg";
      }
    })
    
    
    image.adapter.add("mask", function (mask, target) {
      var circleBullet = target.parent;
      return circleBullet.circle;
    })
    
    var previousBullet;
    var hoverTopValue = 30;
    this.chart.cursor.events.on("cursorpositionchanged", function (event) {
      var dataItem = series.tooltipDataItem;
  
      if (dataItem.column) {
        var bullet = dataItem.column.children.getIndex(1);

        if (previousBullet && previousBullet !== bullet) {
          previousBullet.isHover = false;
        }

        if (previousBullet !== bullet) {
          var hs = bullet.states.getKey("hover");
          hs.properties.dy = -bullet.parent.pixelHeight + hoverTopValue;
          bullet.isHover = true;

          previousBullet = bullet;
        }
      }
    });


    this.chart.events.once("ready", (ev) => {
      //console.log('ev', ev.target.contentWidth);
      if(ev.target.contentWidth < 640){
        columnTemplate.maxWidth = 30;
        bullet.scale = 0.6;
        hoverTopValue = 20;
      }
    });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


  refCallback = (element) => {
    if (element) {
      this.chartContainer = element;
      let sizes = element.getBoundingClientRect();
    }
  };

  render() {
    return (
      <div ref={this.refCallback} id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartColImg;


