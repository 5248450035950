import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { RootStore } from 'stores';
import { PrivateRoute } from 'components';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('views/Pages/Login'));
const Page404 = React.lazy(() => import('views/Pages/Page404'));
const Page500 = React.lazy(() => import('views/Pages/Page500'));

const stores = new RootStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, stores.navigation);



class App extends Component {
  
  isAuthenticated = true;

  render() {

    return (
      <Router history={history}>
        <Provider {...stores}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <PrivateRoute path="/" isAuthenticated={this.isAuthenticated} name="Home" component={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
        </Provider>
      </Router>
    );
  }
}

export default App;
