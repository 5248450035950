import React, { Component } from 'react';

import { am4core, globalSettings, am4Styles, catAxisLabel, columnSerie, scrollbarX } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";


class ChartColLine extends Component {

  componentDidMount() {
    let seriesColor = [this.props.dataSeries.column[2], this.props.dataSeries.line[2]];
    
    this.chart = am4core.create(this.props.id, am4charts.XYChart);
    let watermark = new am4core.Label();
    globalSettings(this.chart, watermark, true, seriesColor);  
    watermark.marginRight = 0;
    this.chart.colors.step = 3;

    this.chart.data = this.props.data;
    
    // Create axes
    var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    //categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 100;
    catAxisLabel(categoryAxis);
    
        
    // First value axis
    var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = this.props.dataSeries['column'][1];
    valueAxis.renderer.grid.template.disabled = true;
    //valueAxis.renderer.minGridDistance = 60;
    valueAxis.min = 0;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#.";

    // Second value axis
    var valueAxisOpposite = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisOpposite.title.text = this.props.dataSeries['line'][1];
    valueAxisOpposite.renderer.opposite = true;
    valueAxisOpposite.renderer.grid.template.disabled = true;


    this.createData(valueAxisOpposite);

    if(this.chart.data.length > 15 || this.props.zoom) scrollbarX(this.chart);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createData = (valueAxisOpposite) => {    
    //this.props.dataSeries.map(item => this.createSeries(valueAxisOpposite, ...item))
    if(this.props.dataSeries.column){
      this.createSeries(null, 'column', ...this.props.dataSeries.column)
    }
    if(this.props.dataSeries.line){
      this.createSeries(valueAxisOpposite, 'line', ...this.props.dataSeries.line)
    }

    /*for (let [key, value] of Object.entries(this.props.dataSeries)) {
      let type = key;
      this.createSeries(valueAxisOpposite, type, ...value)
    }*/
  }

  createSeries = (valueAxisOpposite = null, type, key, name, color, valueY ) => {
    let serie;
    if(type === 'column'){
      serie = this.chart.series.push(new am4charts.ColumnSeries());
      serie.name = name;
      serie.dataFields.valueY = key;
      serie.dataFields.categoryX = "category";
      serie.calculatePercent = true;

      //let valueY = '[bold]{valueY.percent.formatNumber("#.00")}%[/]';
      columnSerie(serie, valueY);    
    }
    if(type === 'line'){
      serie = this.chart.series.push(new am4charts.LineSeries());
      let bullet = serie.bullets.push(new am4charts.CircleBullet());
      serie.dataFields.valueY = key;
      serie.dataFields.categoryX = "category";
      serie.name = name;

      Object.assign(serie, am4Styles.serie);
      Object.assign(bullet, am4Styles.bullet);
      if(color) bullet.fill =  am4core.color(color);
      if(color) serie.stroke = am4core.color(color);
      serie.yAxis = valueAxisOpposite;
  
    }

    return serie;

  }

  render() {
    return (
      <div id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartColLine;


