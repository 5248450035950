import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";

import { Moment } from 'constants/locale';

//https://www.amcharts.com/docs/v4/tutorials/creating-themes/
function am4themes_yugoTheme(target) {
  if (target instanceof am4core.InterfaceColorSet) {
    target.setFor("secondaryButton", am4core.color("#fc4846"));
    target.setFor("secondaryButtonHover", am4core.color("#fc4846").lighten(-0.2));
    target.setFor("secondaryButtonDown", am4core.color("#fc4846").lighten(-0.2));
    target.setFor("secondaryButtonActive", am4core.color("#fc4846").lighten(-0.2));
    target.setFor("secondaryButtonText", am4core.color("#FFFFFF"));
    target.setFor("secondaryButtonStroke", am4core.color("#c32021"));
  }

  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#fc4846")
    ];
  }

}

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_yugoTheme);

const globalSettings = function(chart, watermark, legend = null, legendColor = null){
  let options = chart.exporting.getFormatOptions("pdf");
  //chart.responsive.enabled = true;
  options.addURL = false;
  chart.exporting.setFormatOptions("pdf", options);
  chart.language.locale = am4lang_fr_FR;
  chart.children.push(watermark);
  Object.assign(watermark, am4Styles.watermark);

  if(!legend) return chart;

  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  let marker = chart.legend.markers.template.children.getIndex(0);
  marker.cornerRadius(12, 12, 12, 12);
  marker.strokeWidth = 1;
  marker.strokeOpacity = 0.5;
  marker.stroke = am4core.color("#ccc");
  
  if (legend && legendColor ){
    marker.adapter.add("fill", colorAdapter);  
    function colorAdapter(fill, target) {
      if (target.dataItem && target.dataItem.index && legendColor[target.dataItem.index]) {      
        return am4core.color(legendColor[target.dataItem.index]);
      } else {
        return fill;
      }
    };
  } 
  
  return chart;
}

const scrollbarX = function(chart){
  let scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX = scrollbarX;
  Object.assign(chart.scrollbarX, am4Styles.scrollbarX);
}

const catAxisLabel = function(categoryAxis){
  let label = categoryAxis.renderer.labels.template;
  label.wrap = true;
  //label.maxWidth = 130;
  categoryAxis.events.on("sizechanged", function(ev) {
    let axis = ev.target;
    let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    axis.renderer.labels.template.maxWidth = cellWidth;
  });
}


const columnSerie = function (serie, valueExpression) {
  serie.tooltip.pointerOrientation = "vertical";

  let columnTemplate = serie.columns.template;
  columnTemplate.tooltipY = -6;
  columnTemplate.tooltipText = valueExpression;
  columnTemplate.column.cornerRadius(5, 5, 0, 0);
  columnTemplate.strokeOpacity = 0;
  Object.assign(serie, am4Styles.serie);

  var labelBullet = new am4charts.LabelBullet();
  serie.bullets.push(labelBullet);
  labelBullet.label.text = valueExpression;
  labelBullet.label.fontSize = 12;    
  labelBullet.dy = 20;    
}

const barSerie = function (serie, valueExpression) {
  serie.tooltip.pointerOrientation = "vertical";

  let columnTemplate = serie.columns.template;
  columnTemplate.tooltipY = -3;
  
  columnTemplate.tooltipText = valueExpression;
  columnTemplate.column.cornerRadius(0, 5, 0, 5);
  columnTemplate.strokeOpacity = 0;
  Object.assign(serie, am4Styles.serie);

  var labelBullet = new am4charts.LabelBullet();
  serie.bullets.push(labelBullet);
  labelBullet.label.text = valueExpression;
  labelBullet.label.fontSize = 12;    
  labelBullet.dx = -30;  
  labelBullet.locationX = 0;  
}

const am4Styles = {
  serie: {
    strokeWidth: 2,
    tensionX : 0.8,
  },
  bullet: {
    tooltipText: "{name}: [bold]{valueY}[/]",
    strokeWidth : 3,
  },
  watermark: {
    text: `YUGO © ${Moment().format('YYYY')}`,
    align: "right",
    valign: "bottom",
    fontSize: 15,
    opacity: 0.5,
    marginRight: -40,
    marginBottom: -15,
    url: "https://www.yugo.nc/",
    urlTarget: "_blank",
    layout: "absolute"
  },
  titleAxisY : {
    rotation: 0,
    align: "center",
    valign: "top",
    dy: -40,
    fontWeight: 600
  },
  scrollbarX: {
    marginTop: 0,
    marginBottom: 60
  }
}

export { 
  am4core, 
  am4lang_fr_FR, 
  am4Styles, 
  globalSettings, 
  scrollbarX, 
  catAxisLabel, 
  columnSerie,
  barSerie
};
