import React, { Component } from 'react';

import { am4core, globalSettings, scrollbarX, barSerie } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";



class ChartBar extends Component {

  
  componentDidMount() {

    this.chart = am4core.create(this.props.id, am4charts.XYChart);
    this.chart.colors.step = 3;
    this.chart.paddingRight = 40;

    let watermark = new am4core.Label();
    globalSettings(this.chart, watermark, false, null);    

    this.chart.data = this.props.data;
    
    let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.dataFields.category = "category";
    
    let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;

    let serie = this.chart.series.push(new am4charts.ColumnSeries());
    serie.dataFields.categoryY = "category";
    serie.dataFields.valueX = "value";
    barSerie(serie, "{valueX.value}");

    serie.columns.template.adapter.add("fill", (fill, target) => {     
      return this.chart.colors.getIndex(target.dataItem.index+1);
    });
    
    if(this.props.zoom) scrollbarX(this.chart);
  


    
    //this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartBar;


