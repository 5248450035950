import React from 'react';
import { Col, Label, Input, FormFeedback, FormGroup, FormText, Button, ListGroupItem, ListGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field, FieldArray, getIn } from 'formik';
import DatePicker from 'react-date-picker';
import Select from 'react-select';

import { validation } from 'utils/validation';

const FriendArrayErrors = (errors, name) => typeof errors[name] === 'string' ? <FormText color="danger">{errors[name]}</FormText> : null;
const deleteMsg = {
  products : `Vous voulez supprimer ce produit ?`,
  orderStatus : `Vous voulez supprimer cet état ?`
} 


const ArrayInputGroup = ({
    form: { values, touched, errors, setFieldValue, setFieldTouched, onChange },
    ...arrayHelpers,
  }, props) => (
  <div className="arrayGroup">
    <h5>{props.title}</h5>
  {
    values[props.name].map((item, i) => (
      <FormGroup key={i} row>{FriendArrayErrors(errors, props.name)}
      <Field type={props.type} name={`${props.name}.${i}.price`} label={values[props.name][i].label} component={FieldArrayInputField} required columns={{xs:"7", lg:"8"}} />
      </FormGroup>
    ))
  }
  <FormGroup row>{FriendArrayErrors(errors, props.name)}</FormGroup>
  </div>
)


const MessageTplInputGroup = ({
  form: { values, touched, errors, setFieldValue, setFieldTouched, onChange },
  ...arrayHelpers,
}, props) => {
  const cols = {
    label: { md: 3, xs: 12 },
    input: { md: 9, xs: 12 } 
  }
  return(
    <React.Fragment>
      <FormGroup row>
        <Col>
          <FormText>
            Les variables entre crochet seront remplacées automatiquement :
            <br/>-[nom] et [prenom] du client.
            <br/>-[ticket] et [montant] relatifs à la tache.
            <br/>-[magasin] [telephone] [email] relatifs au magasin.
          </FormText>
        </Col>
      </FormGroup>
        {
          values[props.name].map((item, i) => (
            <SimpleInput key={i} type="textarea" name={`${props.name}[${i}].content`} label={item.title} cols={cols} required />
          ))
        }
        <FormText color="danger">
          Les caractères spéciaux ainsi que les accents ( ê, î, ô ...etc) diminuent le nombre de caractères possibles par SMS. Les éviter au possible.
        </FormText>
      <FormGroup row>{FriendArrayErrors(errors, props.name)}</FormGroup>
    </React.Fragment>
  )
}


export const FieldArrayInputField = ({
  field: { name, value, ...field },
  form: {touched, errors, ...form},
  columns,
  label,
  ...props
}) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const nameKey = name.split(/[.;+_]/).pop();
  const star = props.required ? ' *' : '';
  // {props.required && <span className="text-danger">*</span>}
  return(
    <>
      <Col className="col-12 col-md-3">
        <Label htmlFor={name} className="col-form-label">{label}</Label>
      </Col>
      <Col className="col-12 col-md-9">
        <Input id={name} invalid={touch && Boolean(error)} {...field} {...props} value={value || ''} />
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </>
  )
}

const CustomInputGroup = ({
  field: { name, value, ...field },  // { name, value, onChange, onBlur }
  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  ...props
}) => {
  const error = getIn(errors, name);
  const touch = name === 'passwordConfirm' ? true : getIn(touched, name); 
  
  return(
    <InlineGroup name={name} label={label} {...props}>
      <Input id={name} invalid={touch && Boolean(error)} {...field} {...props} value={value || ''} />
      <FormFeedback>{error}</FormFeedback>
    </InlineGroup>
  )
}


const InputGroupIcon = ({
  field: { name, value, ...field },  // { name, value, onChange, onBlur }
  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  icon,
  ...props
}) => {
  const error = getIn(errors, name);
  const touch = name === 'passwordConfirm' ? true : getIn(touched, name);
  
  return(
    <InputGroup className="mb-3">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={icon}></i>
        </InputGroupText>
      </InputGroupAddon>
      <Input placeholder={label} id={name} invalid={touch && Boolean(error)} {...field} {...props} value={value || ''}/>
      <FormFeedback>{error}</FormFeedback>
    </InputGroup>
  )
}


const InputGroupDisable = ({
  field: { name, ...field },  // { name, value, onChange, onBlur }
  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  ...props
}) => {
    return (
      <React.Fragment>
        <h6>Administrateurs</h6>
        <ListGroup>
          { field.value.map(admin => <ListGroupItem key={admin.objectId}>{admin.username} - {admin.email}</ListGroupItem> ) }
        </ListGroup>
      </React.Fragment>
    )
  
}

const DateGroup = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  onChange,
  label,
  ...props
}) => (
  <InlineGroup name={name} label={label}>
    <DatePicker value={value} name={name}  onChange={(date) => onChange(date, setFieldValue, setFieldTouched)} {...props}/>
    { touched[name] && Boolean(errors[name]) && <FormText color="danger">{errors[name]}</FormText> }
  </InlineGroup>
)

const CheckboxGroup = ({
  field: { onChange, ...field },
  form: {touched, errors, setFieldValue, setFieldTouched, value},
  label,
  checkboxes,
  ...props
}) => {   
  return (
  <InlineGroup name={[field.name]} label={label}>
    <FormGroup inline check>
      { checkboxes.map((c, i) => {
        const nameArr = c.name.split('.');               
        const isChecked = nameArr[0] && nameArr[1] ? field.value[nameArr[0]] && field.value[nameArr[0]][nameArr[1]] ? field.value[nameArr[0]][nameArr[1]] : false : field.value[nameArr[0]] ? field.value[nameArr[0]] : false ;
        
        const onChangeFunction = props.onChange ? (e) => props.onChange(e, onChange, setFieldValue, setFieldTouched, isChecked) : onChange;
        return (
          <Label key={i} className="mr-2 col-form-label" check>
            <Input key={c.name} id={c.name} type="checkbox" {...field} {...props} name={c.name} checked={isChecked} onChange={onChangeFunction} />
            {c.label}
          </Label>
          )
        }) 
      }
    </FormGroup>
  </InlineGroup>
  )
}


const SelectGroup = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  onChange,
  label,
  ...props
}) => (
  <InlineGroup name={name} label={label}>
    <Select id={name} name={name}  value={value} onChange={(result, action) => onChange(result, action, setFieldValue, setFieldTouched)} {...props}/>
    { touched[name] && Boolean(errors[name]) && <FormText color="danger">{errors[name]}</FormText> }
  </InlineGroup>
)

              
export const InlineGroup = ({children, name, label, ...props}) => {
  const labelCol = props.cols ? props.cols.label : { md: 3, xs: 12 };
  const inputCol = props.cols ? props.cols.input : { md: 9, xs: 12 };
  
  return (
    <FormGroup row>
      <Col {...labelCol}>
        <Label htmlFor={name} className="col-form-label">{label} {props.required && <span className="text-danger">*</span>}</Label>
      </Col>
      <Col {...inputCol}>
        { children }
      </Col>
    </FormGroup>
  )
}

export const SimpleInputBasic = ({ name, label, type, value, ...props}) => {
  const labelCol = props.cols ? props.cols.label : { md: 3, xs: 12 };
  const inputCol = props.cols ? props.cols.input : { md: 9, xs: 12 };
  return (
    <FormGroup row>
      <Col {...labelCol}>
        <Label htmlFor={name} className="col-form-label">{label}</Label>
      </Col>
      <Col {...inputCol}>
        <Input id={name} name={name} type={type} value={value} {...props} />
      </Col>
    </FormGroup>
  )
}


export const SimpleInput = props => (
  <Field {...props} component={CustomInputGroup}/>
)


export const SimpleInputIcon = props => (
  <Field {...props} component={InputGroupIcon}/>
)

export const SimpleInputDisable = props => (
  <Field {...props} component={InputGroupDisable}/>
)


export const SimpleCheckbox = props => (
  <Field {...props} component={CheckboxGroup}/>
);

export const SimpleDatePicker = props => (
  <Field {...props} component={DateGroup}/>
);

export const SimpleSelect = props => (
  <Field {...props} component={SelectGroup}/>
);

export const ArrayInput = (props) => (
  <FieldArray name={props.name} render={(form) => ArrayInputGroup(form, props)}/>
);

export const MessageTplInput = (props) => (
  <FieldArray name={props.name} render={(form) => MessageTplInputGroup(form, props)}/>
);

