import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class ExportBtn extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      fileTypes: ['png', 'pdf', 'json', 'csv', 'xlsx']
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleClick = (type) => {
    this.props.exportAction(type)
  }

  render() {
    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color="primary" caret size="sm">
          Exporter
        </DropdownToggle>
        <DropdownMenu right style={{minWidth: '7rem'}}>
          {
            this.state.fileTypes.map(fileType => <DropdownItem key={fileType} style={{textAlign: 'center'}} onClick={() => this.props.exportAction(fileType)}>{fileType}</DropdownItem>)
          }
        </DropdownMenu>
      </ButtonDropdown>

    );
  }
}
