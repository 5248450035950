import React, { Component } from 'react';
import { Button } from 'reactstrap';

import { am4core, globalSettings } from 'components/Charts/am4core'
import * as am4charts from "@amcharts/amcharts4/charts";



class ChartCircle extends Component {

  componentDidMount() {
    this.chart = am4core.create(this.props.id, am4charts.PieChart);
    this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  
    let watermark = new am4core.Label();
    globalSettings(this.chart, watermark, true);    

    //override common watermark style
    watermark.marginBottom = 0;
    watermark.marginRight = 0;

    this.chart.data = this.props.data;
    this.chart.radius = am4core.percent(70);
    this.chart.innerRadius = am4core.percent(40);

    if(this.props.doughnut){
      this.chart.startAngle = 180;
      this.chart.endAngle = 360;  
    }

    var series = this.chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";

    if(this.props.doughnut){
      series.slices.template.cornerRadius = 10;
      series.slices.template.innerCornerRadius = 7;
      series.slices.template.draggable = true;
      series.slices.template.inert = true;
    }
    series.colors.step = 2;
    series.slices.template.propertyFields.fill = "color";

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

  }

  

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id={this.props.id} style={this.props.style}></div>
    );
  }
}

export default ChartCircle;


