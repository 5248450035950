import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { generatePagination, isUndefined } from 'utils/functions';



const PaginatorItem = ({
  active, value, text, onPageChange,
}) => {
  const isPrevious = text === 'previous' ? true : false;
  const isNext = text === 'next' ? true : false;
  text = (text === 'next') || (text === 'previous') ? '' : text;
  
  return(
    <PaginationItem active={active ? true : false}>
      <PaginationLink tag="button"
        onClick={e => onPageChange(e, { activePage: value })}
        disabled={isUndefined(value)}
        next={isNext}
        previous={isPrevious}
      >
        { text }
      </PaginationLink>
    </PaginationItem>
  )
}

const Paginator = (props) => {
  const { activePage, totalPages, onPageChange } = props;
  const paginatorItems = generatePagination(activePage, totalPages);
  
  return (
    <Pagination listClassName="justify-content-center">
      {paginatorItems.map(({ active, value, text }, idx) => (
        <PaginatorItem
          key={isUndefined(value) ? `ellipsis-${idx}` : text}
          active={active}
          value={value}
          text={text}
          onPageChange={onPageChange}
        />
      ))}
    </Pagination>
  )
}

export const PaginatorFetch = (props, obj, totalPages, pageChange, activeCustom) => {  
  const paginatorItems = generatePagination(activeCustom, totalPages);
  const paginatorSize = paginatorItems.length;  
  return (
    <Pagination listClassName="justify-content-center">
      {paginatorItems.map(({ active, value, text }, idx) => {        

        if(text === 'previous' && paginatorItems[1].active){ value = undefined; }
        if(text === 'next' && paginatorItems[paginatorSize-2].active){ value = undefined; }

        return(
          <PaginatorItem
            key={isUndefined(value) ? `ellipsis-${idx}` : text}
            active={active}
            value={value}
            text={text}
            onPageChange={(e, page) => pageChange(props.onPageChange, e, page) }
          />
        )}
      )}
    </Pagination>
  )
}

export default Paginator;