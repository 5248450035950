import moment from 'moment';
import localization from 'moment/locale/fr';
moment.locale('fr', localization);


export const Moment = moment;

export const status = {
  prepared: "Planifiée",
  pending: "En attente",
  check_in: "Check in",
  in_progress: "En cours",
  check_out: "Check out",
  locking: "En clôture",
  closed: "Fermée",
  expired: "Expirée",
  refused: "Refusée",
  cancelled: "Annulée",
}


export const localStings = { status }
