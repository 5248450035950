import { RouterStore } from 'mobx-react-router';

import LayoutStore from "./LayoutStore";
import BreadcrumbStore  from "./BreadcrumbStore";
import DataStore  from "./DataStore";

/*import AuthStore from "./AuthStore";
import NotifStore  from "./NotifStore";*/

export class RootStore {
  constructor() {
    this.layoutStore = new LayoutStore();
    this.pathStore = new BreadcrumbStore();
    this.navigation = new RouterStore();
    this.dataStore = new DataStore();
    /*this.authStore = new AuthStore();
    this.notifStore = new NotifStore(this.shopStore, this.taskStore);*/
  }
}
